<template>

  <div class="row">
    <div class="col-md-3">
      <ul class="nav nav-pills flex-column nav-left">
        <li class="nav-item">
          <router-link
            :to="{ name: 'region' }"
            class="nav-link"
            :class="($route.meta.pageActive == 'region')?'active':''"
          >
            <feather-icon
              icon="FlagIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Region</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'area' }"
            :class="($route.meta.pageActive == 'area')?'active':''"
          >
            <feather-icon
              icon="FlagIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Area Layanan</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'layanan' }"
            :class="($route.meta.pageActive == 'layanan')?'active':''"
          >
            <feather-icon
              icon="FlagIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Unit Layanan Pelanggan</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'kantorjaga' }"
            :class="($route.meta.pageActive == 'kantorjaga')?'active':''"
          >
            <feather-icon
              icon="FlagIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Kantor Jaga</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'wilayah-peta' }"
            :class="($route.meta.pageActive == 'wilayah-peta')?'active':''"
          >
            <feather-icon
              icon="MapIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Peta Sebaran</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="col-md-9">
      <router-view />
    </div>
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      options: {},
    }
  },
  
}
</script>
